/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UsersListChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/users';
import {
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  SETS_SELECTED_WORKSPACE_COMMAND,
  SetsSelectedWorkspaceCommandPort,
  WORKSPACE_ID_GETTER,
  WorkspaceIdGetter,
  TEAM_ID_GETTER,
  TeamIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import {
  CobiroProWorkspaceSwitchedEvent,
  UUID,
  WorkspaceSelectedEvent,
} from '@app.cobiro.com/core/events';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  EMPTY,
  filter,
  map,
  merge,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
  zip,
} from 'rxjs';
import { CreateWorkspaceModalComponent } from '../../adapters/primary/ui/modals/create-workspace-modal/create-workspace-modal.component';
import { DeleteWorkspaceModalComponent } from '../../adapters/primary/ui/modals/delete-workspace-modal/delete-workspace-modal.component';
import { EditWorkspaceModalComponent } from '../../adapters/primary/ui/modals/edit-workspace-modal/edit-workspace-modal.component';
import { CreatesWorkspaceCommand } from '../ports/primary/creates-workspace.command';
import { CreatesWorkspaceCommandPort } from '../ports/primary/creates-workspace.command-port';
import { DeletesWorkspaceCommand } from '../ports/primary/deletes-workspace.command';
import { DeleteWorkspaceCommandPort } from '../ports/primary/deletes-workspace.command-port';
import { EditWorkspaceCommand } from '../ports/primary/edits-workspace.command';
import { EditWorkspaceCommandPort } from '../ports/primary/edits-workspace.command-port';
import { GetsMemberWorkspacesListQuery } from '../ports/primary/gets-member-workspace-list.query-port';
import { GetsSelectedWorkspaceQueryPort } from '../ports/primary/gets-selected-workspace.query-port';
import { GetsWorkspacesListQuery } from '../ports/primary/gets-workspace-list.query';
import { GetsWorkspacesQuery } from '../ports/primary/gets-workspaces.query';
import { HasSubscriptionsQueryPort } from '../ports/primary/has-subscriptions.query-port';
import { LoadsWorkspacesQueryPort } from '../ports/primary/loads-workspaces.query-port';
import { SelectedWorkspaceQuery } from '../ports/primary/selected-workspace.query';
import { SetsSearchPhraseCommand } from '../ports/primary/sets-search-phrase.command';
import { SetsSearchPhraseCommandPort } from '../ports/primary/sets-search-phrase.command-port';
import { SwitchesWorkspaceCommandPort } from '../ports/primary/switches-workspace.command-port';
import { WorkspaceQuery } from '../ports/primary/workspace.query';
import {
  PATCHES_WORKSPACES_CONTEXT_STORAGE,
  PatchesWorkspacesContextStoragePort,
} from '../ports/secondary/context/patches-workspaces-context.storage-port';
import {
  SELECTS_WORKSPACES_CONTEXT_STORAGE,
  SelectsWorkspacesContextStoragePort,
} from '../ports/secondary/context/selects-workspaces-context.storage-port';
import { WorkspacesContext } from '../ports/secondary/context/workspaces-context';
import {
  CreatesWorkspaceDtoPort,
  CREATES_WORKSPACE_DTO,
} from '../ports/secondary/dto/creates-workspace.dto-port';
import {
  GETS_ALL_WORKSPACES_DTO,
  GetsAllWorkspacesDtoPort,
} from '../ports/secondary/dto/gets-all-workspaces.dto-port';
import {
  GetsMemberWorkspacesDtoPort,
  GETS_MEMBER_WORKSPACES_DTO,
} from '../ports/secondary/dto/gets-member-workspaces.dto-port';
import {
  HasSubscriptionsDtoPort,
  HAS_SUBSCRIPTIONS_DTO,
} from '../ports/secondary/dto/has-subscriptions.dto-port';
import { WorkspaceDto } from '../ports/secondary/dto/workspace.dto';
import {
  DELETES_WORKSPACE_DTO,
  DeletesWorkspaceDtoPort,
} from '../ports/secondary/dto/deletes-workspace.dto-port';
import {
  EDITS_WORKSPACE_NAME_DTO,
  EditsWorkspaceNameDtoPort,
} from '../ports/secondary/dto/edits-workspace-name.dto-port';
import { IsCurrentWorkspaceCommandPort } from '../ports/primary/is-current-workspace.command-port';
import { GetsAllTeamQueryPort } from '../ports/primary/gets-all-teams.query-port';
import { TeamQuery } from '../ports/primary/team.query';
import {
  GETS_ALL_TEAMS_DTO,
  GetsAllTeamsDtoPort,
} from '../ports/secondary/dto/gets-all-team-dto.port';
import { GetsMemberWorkspacesListByTeamQuery } from '../ports/primary/gets-member-workspace-list-by-team.query-port';
import { ClientsListChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/clients';

@Injectable()
export class WorkspacesState
  implements
    GetsSelectedWorkspaceQueryPort,
    GetsWorkspacesQuery,
    LoadsWorkspacesQueryPort,
    SetsSearchPhraseCommandPort,
    GetsWorkspacesListQuery,
    CreatesWorkspaceCommandPort,
    EditWorkspaceCommandPort,
    DeleteWorkspaceCommandPort,
    HasSubscriptionsQueryPort,
    GetsMemberWorkspacesListQuery,
    SwitchesWorkspaceCommandPort,
    IsCurrentWorkspaceCommandPort,
    GetsAllTeamQueryPort,
    GetsMemberWorkspacesListByTeamQuery
{
  private readonly _workspaceList$ = new BehaviorSubject<WorkspaceDto[]>([]);

  constructor(
    @Inject(GETS_ALL_WORKSPACES_DTO)
    private readonly _getAllWorkspacesDto: GetsAllWorkspacesDtoPort,
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQueryPort: GetsCobiroProContextQueryPort,
    @Inject(APPLICATION_BUS) readonly _applicationBus: ApplicationBus,
    @Inject(SETS_SELECTED_WORKSPACE_COMMAND)
    private readonly _setsSelectedWorkspaceCommandPort: SetsSelectedWorkspaceCommandPort,
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(PATCHES_WORKSPACES_CONTEXT_STORAGE)
    private readonly _patchesWorkspacesContextStoragePort: PatchesWorkspacesContextStoragePort,
    @Inject(SELECTS_WORKSPACES_CONTEXT_STORAGE)
    private readonly _selectsWorkspacesContextStoragePort: SelectsWorkspacesContextStoragePort,
    @Inject(HAS_SUBSCRIPTIONS_DTO)
    private readonly _hasSubscriptionsDtoPort: HasSubscriptionsDtoPort,
    @Inject(CREATES_WORKSPACE_DTO)
    private readonly _createsWorkspaceDtoPort: CreatesWorkspaceDtoPort,
    @Inject(GETS_MEMBER_WORKSPACES_DTO)
    private readonly _getsMemberWorkspacesDtoPort: GetsMemberWorkspacesDtoPort,
    @Inject(DELETES_WORKSPACE_DTO)
    private readonly _deletesWorkspaceDtoPort: DeletesWorkspaceDtoPort,
    @Inject(EDITS_WORKSPACE_NAME_DTO)
    private readonly _editsWorkspaceNameDtoPort: EditsWorkspaceNameDtoPort,
    @Inject(GETS_ALL_TEAMS_DTO)
    private readonly _getsAllTeamsDtoPort: GetsAllTeamsDtoPort,
    private readonly _matDialog: MatDialog,
    private readonly _alert: HuiAlert,
  ) {
    merge(
      this._applicationBus.on(ClientsListChangedEvent),
      this._applicationBus.on(UsersListChangedEvent),
    )
      .pipe(switchMap(() => this.loadWorkspaces(this._teamIdGetter.getTeamId())))
      .subscribe();
  }
  getAllTeam(): Observable<TeamQuery[]> {
    return this._getsAllTeamsDtoPort.getsAll({});
  }

  isCurrentWorkspace(workspaceId: string): Observable<boolean> {
    return of(workspaceId === this._workspaceIdGetter.getWorkspaceId());
  }

  switchWorkspace(workspace: WorkspaceQuery): Observable<void> {
    return of(
      this._applicationBus.dispatch(
        new CobiroProWorkspaceSwitchedEvent(
          workspace.id,
          workspace.name,
          workspace.avatar,
          workspace.membersCount,
          workspace.clientCount,
        ),
      ),
    );
  }

  getMemberWorkspaceListQuery(): Observable<WorkspaceQuery[]> {
    return this._workspaceList$
      .asObservable()
      .pipe(map(workspaces => workspaces.filter(workspace => workspace.isAvailableForUser)));
  }

  hasSubscriptions(workspaceId: string): Observable<boolean> {
    return this._hasSubscriptionsDtoPort.hasSubscriptions(workspaceId);
  }

  deleteWorkspace(command: DeletesWorkspaceCommand): Observable<void> {
    return this._selectsWorkspacesContextStoragePort.select().pipe(
      take(1),
      map(context => context.list.find(workspace => workspace.id === command.id)),
      switchMap(workspace =>
        this._matDialog
          .open(DeleteWorkspaceModalComponent, {
            minWidth: '400px',
            maxWidth: '700px',
            panelClass: 'cs-mat-dialog',
            data: {
              id: workspace.id,
              name: workspace.name,
              route: command.route,
            },
          })
          .afterClosed()
          .pipe(concatMap(result => (result ? this._deleteWorkspace(command) : of(void 0)))),
      ),
    );
  }

  private _deleteWorkspace(command: DeletesWorkspaceCommand): Observable<void> {
    return this._deletesWorkspaceDtoPort
      .deleteWorkspace({
        teamId: this._teamIdGetter.getTeamId(),
        workspaceId: command.id,
      })
      .pipe(
        switchMap(() =>
          this._selectsWorkspacesContextStoragePort.select().pipe(
            take(1),
            map(usersContext => usersContext.list),
            tap({
              next: (workspaces: WorkspaceDto[]) => {
                const updatedWorkspaces: WorkspaceDto[] = workspaces.filter(
                  workspace => workspace.id !== command.id,
                );
                this._workspaceList$.next(updatedWorkspaces);
                this._patchesWorkspacesContextStoragePort.patch({ list: [...updatedWorkspaces] });
                this._alert.open(
                  'success',
                  'cobiro_pro_workspace_delete_workspace_success_message',
                );
              },
              error: e => {
                this._alert.open('error', 'cobiro_pro_workspace_delete_workspace_failed_message');
                return throwError(() => e);
              },
            }),
            map(() => void 0),
          ),
        ),
      );
  }

  editWorkspace(command: EditWorkspaceCommand): Observable<void> {
    const teamId = this._teamIdGetter.getTeamId();
    return this._selectsWorkspacesContextStoragePort.select().pipe(
      take(1),
      map((context: WorkspacesContext) => context.list.find(item => item.id === command.id)),
      switchMap((workspace: WorkspaceDto) =>
        this._matDialog
          .open(EditWorkspaceModalComponent, {
            minWidth: '500px',
            maxWidth: '700px',
            panelClass: 'cs-mat-dialog',
            data: { route: command.route, workspaceName: workspace.name },
          })
          .afterClosed()
          .pipe(
            take(1),
            filter(Boolean),
            switchMap(workspaceFormData =>
              this._editsWorkspaceNameDtoPort
                .editWorkspaceName({
                  teamId: teamId,
                  workspaceId: command.id,
                  workspaceName: workspaceFormData.name,
                })
                .pipe(
                  switchMap(() => this._selectsWorkspacesContextStoragePort.select().pipe(take(1))),
                  switchMap((context: WorkspacesContext) => {
                    const updatedWorkspaces: WorkspaceDto[] = context.list.map(workspace =>
                      workspace.id === command.id
                        ? { ...workspace, ...workspaceFormData }
                        : workspace,
                    );
                    this._workspaceList$.next(updatedWorkspaces);
                    return this._patchesWorkspacesContextStoragePort.patch({
                      list: [...updatedWorkspaces],
                    });
                  }),
                  tap(() => {
                    this._alert.open('success', 'cobiro_pro_workspace_edit_form_submit_success');
                  }),
                  map(() => void 0),
                ),
            ),
          ),
      ),
    );
  }

  setSearchPhrase(command: SetsSearchPhraseCommand): Observable<void> {
    return this._selectsWorkspacesContextStoragePort.select().pipe(
      take(1),
      map((context: WorkspacesContext) =>
        context.list.filter((user: WorkspaceDto) =>
          user.name.toLowerCase().includes(command.phrase.toLowerCase()),
        ),
      ),
      tap((workspaces: WorkspaceDto[]) => this._workspaceList$.next(workspaces)),
      map(() => void 0),
    );
  }

  getMemberWorkspaceListByTeam(teamId: string): Observable<WorkspaceDto[]> {
    return this._getsMemberWorkspacesDtoPort.getsMembersWorkspace(teamId);
  }

  loadWorkspaces(teamId: string): Observable<WorkspaceDto[]> {
    return combineLatest([
      this._getAllWorkspacesDto.getsAll(teamId),
      this._getsMemberWorkspacesDtoPort.getsMembersWorkspace(teamId),
    ]).pipe(
      map(([workspaces, memberWorkspaces]: [WorkspaceDto[], WorkspaceDto[]]) =>
        workspaces.map(workspace => ({
          ...workspace,
          isAvailableForUser: memberWorkspaces.some(item => item.id === workspace.id),
        })),
      ),
      switchMap((dtos: WorkspaceDto[]) => {
        this._workspaceList$.next(dtos);
        return zip(
          this._patchesWorkspacesContextStoragePort.patch({ list: dtos }),
          this._setSelectedWorkspace(dtos),
        ).pipe(map(() => dtos));
      }),
    );
  }

  getSelectedWorkspace(): Observable<SelectedWorkspaceQuery> {
    return combineLatest([
      this._getsCobiroProContextQueryPort.getContext(),
      this._selectsWorkspacesContextStoragePort.select().pipe(map(context => context.list)),
    ]).pipe(
      switchMap(([context, dtos]) => {
        const selectedWorkspace = dtos.find(dto => dto.id === context.selectedWorkspaceId);
        if (!selectedWorkspace && dtos.length > 0) {
          const defaultWS = dtos.at(0);
          return this._setsSelectedWorkspaceCommandPort
            .setSelectedWorkspace(
              defaultWS.id,
              defaultWS.name,
              defaultWS.avatar,
              defaultWS.membersCount,
            )
            .pipe(
              take(1),
              map(() => defaultWS),
            );
        }
        return of(selectedWorkspace);
      }),
      filter(Boolean),
      map(selectedWorkspaceDto => {
        return new SelectedWorkspaceQuery(
          selectedWorkspaceDto.name,
          selectedWorkspaceDto.membersCount,
          selectedWorkspaceDto.clientCount,
        );
      }),
    );
  }

  getWorkspaceListQuery(): Observable<WorkspaceQuery[] | null> {
    return this._workspaceList$
      .asObservable()
      .pipe(
        map(list =>
          this._adaptToQuery(
            list.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
          ),
        ),
      );
  }

  getWorkspacesQuery(): Observable<WorkspaceQuery[] | null> {
    return this._selectsWorkspacesContextStoragePort
      .select()
      .pipe(map(context => context.list && this._adaptToQuery(context.list)));
  }

  incrementWorkspaceMembersCount(userQuantity: number): Observable<void> {
    return this._selectsWorkspacesContextStoragePort.select().pipe(
      take(1),
      map(context => context.list),
      switchMap(list => {
        const updatedList = list.map(obj =>
          obj.id === this._workspaceIdGetter.getWorkspaceId()
            ? { ...obj, membersCount: userQuantity }
            : obj,
        );
        this._workspaceList$.next(updatedList);
        return this._patchesWorkspacesContextStoragePort.patch({ list: updatedList });
      }),
    );
  }

  private _adaptToQuery(workspaces: WorkspaceDto[]): WorkspaceQuery[] {
    return workspaces.map(
      ({ id, name, avatar, membersCount, clientCount }) =>
        new WorkspaceQuery(
          id,
          name,
          avatar,
          membersCount,
          clientCount,
          id === this._workspaceIdGetter.getWorkspaceId(),
        ),
    );
  }

  private _setSelectedWorkspace(dtos: WorkspaceDto[]): Observable<boolean> {
    if (!this._workspaceIdGetter.getWorkspaceId() && dtos.length > 0) {
      const selectedWorkspace = dtos.at(0);
      return this._setsSelectedWorkspaceCommandPort
        .setSelectedWorkspace(
          selectedWorkspace.id,
          selectedWorkspace.name,
          selectedWorkspace.avatar,
          selectedWorkspace.membersCount,
        )
        .pipe(take(1));
    }
    return of(true);
  }

  createWorkspace(command: CreatesWorkspaceCommand): Observable<void> {
    return this._matDialog
      .open(CreateWorkspaceModalComponent, {
        panelClass: 'cs-mat-dialog',
        data: { route: command.route, shouldBeRedirected: command.shouldBeRedirected },
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(workspaceFormData =>
          this._createsWorkspaceDtoPort
            .createWorkspace({
              teamId: this._teamIdGetter.getTeamId(),
              workspaceName: workspaceFormData.name,
            })
            .pipe(
              switchMap((res: Pick<WorkspaceDto, 'id' | 'name'>) =>
                this.loadWorkspaces(this._teamIdGetter.getTeamId()).pipe(
                  switchMap(() =>
                    command.shouldBeRedirected ? this._selectAfterCreation(res.id) : EMPTY,
                  ),
                ),
              ),
              tap({
                next: () => {
                  this._alert.open('success', 'cobiro_pro_workspace_create_new_success');
                  if (command.shouldBeRedirected) {
                    this._applicationBus.dispatch(
                      new WorkspaceSelectedEvent(this._teamIdGetter.getTeamId()),
                    );
                  }
                },
                error: err => {
                  this._alert.open('error', '_something_went_wrong');
                  return throwError(() => err);
                },
              }),
            ),
        ),
        map(() => void 0),
      );
  }

  private _selectAfterCreation(id: string): Observable<void> {
    const workspace = this._workspaceList$.getValue().find(item => item.id === id);
    return this.switchWorkspace(workspace);
  }
}
